// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tqAXz2dh{align-items:flex-start;display:flex;padding:max(2.25rem,min(3.33vw,4rem)) 10vw}.Eld6UGov{flex-grow:1;margin:0 auto;max-width:43.75rem}.M56tektz{font-size:max(1.25rem,min(1.67vw,2rem));line-height:1.17;margin-bottom:1em}.M56tektz,.jZNwmuuR{word-wrap:break-word}.jZNwmuuR{font-size:.88rem;line-height:1.3;margin-bottom:max(.88rem,min(1.46vw,1.75rem));opacity:.6}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "tqAXz2dh",
	"formContainer": "Eld6UGov",
	"heading": "M56tektz",
	"warning": "jZNwmuuR"
};
module.exports = ___CSS_LOADER_EXPORT___;
