
import Vue from "vue"

const statuses = [
  "hraniteli-trop",
  "provodniki",
  "roiv",
  "press",
] as const
type Status = typeof statuses[number]

export default Vue.extend({
  layout: "blank",

  middleware: [
    "auth",

    ({ route, redirect }) => {
      const status = route.query.status as Status
      if (!statuses.includes(status))
        return redirect("/", { status: statuses[0] })
    },
  ],

  auth: "guest",

  layoutSettings: {
    titleToken: "pages.auth.registration.title",
  },

  data: () => ({
    isMounted: false,
    done: false,
  }),

  computed: {
    status (): Status {
      return this.$route.query.status as Status
    },
  },

  mounted () {
    this.isMounted = true
  },
})
